<template>
  <v-container
    class="servicos_de_suporte t_az_1 pt-1 pb-1 mt-0 mb-0"
    id="servicos_de_suporte"
    fluid

  >
    <div align="center">
      <template>
        <v-card class="mx-auto pt-14 pb-14" shaped color="t_az_1" elevation="0">
          <v-card-text>
            <div>
              <lord-icon
                src="https://cdn.lordicon.com/gxulgxck.json"
                colors="primary:#000000,secondary:#000000"
                trigger="loop"
                style="width: 60px; height: 60px"
              >
              </lord-icon>
            </div>
            <p class="text-h4 text--primary">Serviços de Suporte</p>
              <span class="font-weight-regular text-h6">
                Prestamos serviços exclusivamente para Contadores. <br> <br>

                Elaboramos toda a documentação do Setor de Registro dos Escritórios Contábeis. <br> <br>

                O Setor de Registro refere-se: Junta Comercial, Cartório, OAB, <br>

                Receita Federal, Receita Estadual, Prefeitura, <br>
                Registro de Marcas, <br>
                Contrato de Prestação de Serviços Contábeis.
              </span>
          </v-card-text>
        </v-card>
      </template>
    </div>
  </v-container>
</template>

<script>
export default {
  data () {
    // return {}
  }
}
</script>
