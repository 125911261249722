<template>
  <v-container class="amostra t_az_1 pt-1 pb-1 mt-0 mb-0" id="amostra" fluid>
    <div align="center">
      <template>
        <v-card class="mx-auto pt-14 pb-14" shaped color="t_branco" elevation="2">
          <v-card-text>
            <div>
              <lord-icon
                src="https://cdn.lordicon.com/puvaffet.json"
                colors="primary:#000000,secondary:#000000"
                trigger="loop"
                style="width: 60px; height: 60px"
              >
              </lord-icon>
            </div>
            <p class="text-h4 text--primary">Nosso Trabalho</p>

            <p class="font-weight-regular text-h5 text--primary">
              <lord-icon
                src="https://cdn.lordicon.com/wloilxuq.json"
                trigger="loop"
                colors="primary:#000000,secondary:#000000"
                style="width: 48px; height: 48px"
              >
              </lord-icon>
              Amostra 1
            </p>
            <span class="font-weight-regular text-h6">Tempo de resposta: 10 minutos.</span> <br>
            <span class="font-weight-regular text-h6">Material utilizado: Código Civil.</span>

            <br><br>
            <v-row justify="center">
              <v-dialog v-model="dialog" width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <!-- <v-btn color="t_az_2" v-bind="attrs" v-on="on" rounded dark class="v-size--x-large"> -->
                  <v-btn color="t_az_2" v-bind="attrs" v-on="on" rounded dark>
                    Clique Aqui
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5"
                      >Uma Cláusula de Contrato Social de Holding</span
                    >
                  </v-card-title>
                  <v-card-text>
                    <p class="font-weight-regular text-h6 text-justify">
                    <strong>Breve Explicação:</strong> <br>
                    Há vários tipos de contratos previstos em nosso Código Civil,
                    um dos princípios que regem os contratos é a liberdade contratual das partes.
                    Cada pessoa é livre para acordar o quê
                    quiser, com quem quiser, desde que seja algo lícito e não
                    contrário à lei.
                    <br>
                    <br>
                    O contrato possui valor entre as partes contratantes e é
                    desconsiderado naquilo que tolir o direito de terceiros
                    quando contrário ao Direito Ambiental, Civil, Trabalhista,
                    Tributário e à Constituição Federal.
                    <br>
                    <br>
                    <strong>Cláusula:</strong> <br>
                    "Os contratantes, do presente instrumento, livremente,
                    acordam que pelo CNPJ tal1 será responsável, por prazo
                    indeterminado, perante terceiros e perante os próprios
                    contratantes, única e isoladamente Fulano de Tal pelo
                    passivo ambiental, civil, financeiro, trabalhista,
                    tributário e outras dívidas.
                    <br>
                    <br>
                    Também, acordam que pelo CNPJ tal2 será responsável, por
                    prazo indeterminado, perante terceiros e perante os próprios
                    contratantes, única e isoladamente Beltrano de Tal pelo
                    passivo ambiental, civil, financeiro, trabalhista,
                    tributário e outras dívidas.
                    <br>
                    <br>
                    A responsabilidade das partes, de cada pessoa jurídica, isto
                    é, de cada CNPJ que compõem o quadro societário da presente
                    Holding não poderá ser atribuída regressiva, nem retroativa,
                    nem solidariamente às demais pessoas jurídicas e nem aos
                    seus respectivos quadros societários.
                    <br>
                    <br>
                    Se a presente cláusula for desconsiderada ou violada, caberá
                    indenização razoável e justa às partes prejudicadas."
                    </p>
                  </v-card-text>
                  <v-card-actions> </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>

            <br><br>

            <p class="text-h5 text--primary">
              <lord-icon
                src="https://cdn.lordicon.com/nocovwne.json"
                trigger="loop"
                colors="primary:#000000,secondary:#000000"
                style="width: 48px; height: 48px"
              >
              </lord-icon>
              Amostra 2
            </p>
            <span class="font-weight-regular text-h6">Tempo de resposta: 3 dias.</span> <br>
            <span class="font-weight-regular text-h6">Material utilizado: Constituição Federal e Código Civil.</span>
            <br>
            <br>
            <span class="black--text font-weight-regular text-h6">
              Um dos Cartórios de Registro de Pessoas Jurídicas de Porto Alegre
              <br>
              solicitou por telefone a autorização <br>
              para usar esse estatuto criado pela Elevare como modelo.
            </span>
            <br>
            <br>
            <v-row justify="center">
              <v-dialog v-model="dialog" width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <!-- <v-btn color="t_az_2" v-bind="attrs" v-on="on" rounded dark class="v-size--x-large"> -->
                  <v-btn color="t_az_2" v-bind="attrs" v-on="on" rounded dark>
                    Clique Aqui
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Estatuto de Associação</span>
                  </v-card-title>
                  <v-card-text>
                    <p class="font-weight-regular text-h6 text-justify">
                      Você será direcionado para poder visualizar ou baixar o
                      Estatuto COMPLETO de Associação. <br> <br>
                      Clique Aqui > Arquivo > Fazer Download > ...
                    </p> <br>
                    <a :href="amostra2" target="_blank" class="font-weight-regular text-h6"> Clique Aqui.</a>
                  </v-card-text>
                  <v-card-actions>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
            <br>
          </v-card-text>
        </v-card>
      </template>
    </div>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      amostra2: 'https://docs.google.com/document/d/1_CGdUvoBlOokKVcgOY2fb6kjY8TgvkyUm-t7iMj1up8/edit?usp=sharing'
    }
  }
}
</script>
