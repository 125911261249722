<template>
  <v-container class="fale t_az_1 pt-1 pb-2 mt-0 mb-0" id="fale" fluid>
    <div align="center">
      <template>
        <v-card class="mx-auto pt-14 pb-14" shaped color="t_az_1" elevation="0" >
          <v-card-text>
            <div>
              <lord-icon
                src="https://cdn.lordicon.com/zpxybbhl.json"
                colors="primary:#000000,secondary:#000000"
                trigger="loop"
                style="width: 60px; height: 60px"
              >
              </lord-icon>
            </div>
            <p class="text-h4 text--primary">Fale</p>
            <span class="font-weight-regular text-h6">Nosso e-mail é informado apenas para Contadores.</span> <br>
            <span class="font-weight-regular text-h6">Deseja ser nosso cliente?</span> <br>
            <span class="font-weight-regular text-h6">
              <v-icon aria-hidden="false"> mdi-whatsapp </v-icon>
              (+55) 55 99196 8605 ou
              <a :href="link" target="_blank"> clique aqui.</a>
            </span>
          </v-card-text>
        </v-card>
      </template>
    </div>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      link: 'https://wa.me/5555991968605'
    }
  }
}
</script>
