<template>
  <v-container class="equipe t_az_1 pt-1 pb-1 mt-0 mb-0" id="equipe" fluid>
    <div align="center">
      <template>
        <v-card class="mx-auto pt-14 pb-14" shaped color="t_az_1" elevation="0">
          <v-card-text>
            <div>
              <lord-icon
                src="https://cdn.lordicon.com/ssdupzsv.json"
                colors="primary:#000000,secondary:#000000"
                trigger="loop"
                style="width: 60px; height: 60px"
              >
              </lord-icon>
            </div>
            <p class="text-h4 text--primary">Equipe</p>

            <div>
              <v-avatar color="white" size="86">
                <v-avatar color="white" size="80">
                  <img src="..\..\public\img\avatar\avatar1.jpg" alt="DCW" />
                </v-avatar>
              </v-avatar>
            </div>
            <span class="text-h5 text--primary">Danieli Celita Waldow</span> <br>
            <span class="font-weight-regular text-h6">CEO.</span> <br>
            <span class="font-weight-regular text-h6">Acadêmica em Contabilidade e Engenharia.</span>
            <br> <br>

            <div>
              <v-avatar color="white" size="86">
                <v-avatar color="white" size="80">
                  <img src="..\..\public\img\avatar\avatar2.jpg" alt="GGS" />
                </v-avatar>
              </v-avatar>
            </div>
            <span class="text-h5 text--primary">GGS</span> <br>
            <span class="font-weight-regular text-h6">Fundador.</span> <br>

            <span class="font-weight-regular text-h6">Acadêmico em múltiplas áreas.</span> <br> <br>
            <span class="font-weight-regular text-h6 text--primary">História</span> <br>
            <span class="font-weight-regular text-h6" v-html="ggs_texto"></span> <br> <br> <br>
            <span class="font-weight-regular text-h6 text--primary" v-html="ggs_frase"></span>
          </v-card-text>
        </v-card>
      </template>
    </div>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      ggs_texto: `
        Sozinho, GGS escreveu a sua primeira palavra antes de ir à escola. <br>

        JD (nome abreviado por sigilo ético), mestre pela USP, considerou o GGS <br>
        como <i>"seu melhor aluno, a mente mais brilhante que ele conheceu <br>entre todos os seus alunos durante os 22 anos em que lecionou na UFSM"</i>. <br> <br>

        Após, ingressou no curso de Direito, estudou para os dois concursos mais difíceis do Brasil. <br>

        GGS gabaritava as provas anteriores dos concursos de Juiz Federal e Procurador da República <br>
        referente às disciplinas que já havia estudado durante 2,5 anos. <br> <br>

        Um dia, precisou de ajuda, ninguém soube ajudar. <br>
        Assim, tornou-se estudante serial — para ajudar as outras pessoas. <br> <br>
        Em homenagem à mãe, que foi tolida de estudar em sua infância; e, ao pai, analfabeto.
      `,
      ggs_frase: `
        <i>"Sabendo que quase todas as pessoas são iguais em capacidades cognitivas, <br>
        e geralmente as outras possuem grandes recursos financeiros, <br>
        minha única oportunidade de igualdade é se eu estudar". <br> <br>

        "É preciso humildade para sentar-se na condição de aluno, <br>
         levantar a mão perante os demais e dizer em alta voz — 'eu não sei'."</i>
    `
    }
  }
}
</script>
