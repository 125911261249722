<template>
  <v-carousel id="inicio"
  cycle
  height="100vh"
  >
    <v-carousel-item
      v-for="(item, i) in items"
      :key="i"
      :src="item.src"
      reverse-transition="fade-transition"
      transition="fade-transition"
    >
     <!-- xs <600px -->

      <v-row class="fill-height d-flex d-sm-none" align="center" justify="center">
        <div class="text-h5" style="color: #000000" v-if="i === 0">
          <div>{{ saudacao() }}, Contador</div>
          <br> <br> <br>
          <div align="center" justify="center">A gente te ajuda...</div>
        </div>

        <div class="text-h5" style="color: #000000" v-if="i === 1">
          <br><br><br><br>
          <div align="center" justify="center">... a cuidar dos<br> seus documentos...</div>
        </div>

        <div class="text-h5" style="color: #000000" v-if="i === 2">
          <br><br><br><br>
          <div align="center" justify="center">... e ainda nos<br> lembramos de você.</div>
        </div>

        <div class="text-h5" style="color: #000000" v-if="i === 3">
          <br><br><br><br>
          <div>Não conte para ninguém...</div>
        </div>

        <div class="text-h5" style="color: #000000" v-if="i === 4">
          <br><br><br><br>
          <div>... deixe com a gente.</div>
        </div>
      </v-row>

      <!-- sm 600px a 960 px -->

      <v-row class="fill-height d-none d-sm-flex d-md-none" align="center" justify="center">
        <div class="text-h4" style="color: #000000" v-if="i === 0">
          <div>{{ saudacao() }}, Contador</div>
          <br> <br> <br>
          <div align="center" justify="center">A gente te ajuda...</div>
        </div>

        <div class="text-h4" style="color: #000000" v-if="i === 1">
          <br><br><br><br>
          <div>... a cuidar dos seus documentos...</div>
        </div>

        <div class="text-h4" style="color: #000000" v-if="i === 2">
          <br><br><br><br>
          <div>... e ainda nos lembramos de você.</div>
        </div>

        <div class="text-h4" style="color: #000000" v-if="i === 3">
          <br><br><br><br>
          <div>Não conte para ninguém...</div>
        </div>

        <div class="text-h4" style="color: #000000" v-if="i === 4">
          <br><br><br><br>
          <div>... deixe com a gente.</div>
        </div>
      </v-row>

      <!-- see in all -->

      <v-row class="fill-height d-flex" align="center" justify="center">
        <div class="text-h2" style="color: #000000" v-if="i === 0">
          <div>{{ saudacao() }}, Contador</div>
          <br> <br> <br>
          <div align="center" justify="center">A gente te ajuda...</div>
        </div>

        <div class="text-h2" style="color: #000000" v-if="i === 1">
          <br><br><br><br>
          <div>... a cuidar dos seus documentos...</div>
        </div>

        <div class="text-h2" style="color: #000000" v-if="i === 2">
          <br><br><br><br>
          <div>... e ainda nos lembramos de você.</div>
        </div>

        <div class="text-h2" style="color: #000000" v-if="i === 3">
          <br><br><br><br>
          <div>Não conte para ninguém...</div>
        </div>

        <div class="text-h2" style="color: #000000" v-if="i === 4">
          <br><br><br><br>
          <div>... deixe com a gente.</div>
        </div>
      </v-row>

    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: 'HelloWorld',

  data: () => ({
    items: [
      {
        src: './img/baners/foto1.jpg'
      },
      {
        src: './img/baners/foto2.jpg'
      },
      {
        src: './img/baners/foto3.jpg'
      },
      {
        src: './img/baners/foto4.jpg'
      },
      {
        src: './img/baners/foto5.jpg'
      }
    ]
  }),

  methods: {
    saudacao: function () {
      this.h = new Date().toLocaleTimeString('pt-BR', {
        hour: 'numeric',
        hour12: false
      })

      if (this.h >= 0 && this.h <= 5) {
        return 'Boa madrugada'
      } else if (this.h >= 6 && this.h < 12) {
        return 'Bom dia'
      } else if (this.h >= 12 && this.h < 18) {
        return 'Boa tarde'
      } else if (this.h >= 18 && this.h <= 23) {
        return 'Boa noite'
      }
    }
  }
}
</script>
