<template>
  <div>
    <inicio/>
    <servicos-de-suporte/>
    <amostra/>
    <sigilo-de-honorarios/>
    <free/>
    <equipe/>
    <empresa/>
    <fale/>
    <rodape/>
  </div>
</template>

<script>
import Inicio from './b_Baner.vue'
import ServicosDeSuporte from './c_Servicos_de_Suporte'
import Amostra from './d_Amostra'
import SigiloDeHonorarios from './e_Sigilo_de_Honorarios'
import Free from './f_Free'
import Equipe from './g_Equipe'
import Empresa from './h_Empresa'
import Fale from './i_Fale'
import Rodape from './j_Rodape'

export default {
  name: 'Home',

  components: {
    Inicio,
    ServicosDeSuporte,
    Amostra,
    SigiloDeHonorarios,
    Free,
    Equipe,
    Empresa,
    Fale,
    Rodape
  }
}
</script>
